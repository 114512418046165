import React from "react";
import { ProjectList } from "../../../data/ProjectData";
import {
  Card,
  CardLeft,
  CardRight,
  TechCardContainer,
  TechCard,
  BtnGroup,
} from "./ProjectCardElements";
import { AnimationOnScroll } from 'react-animation-on-scroll';
function ProjectCard() {
  return (
    <>
      {ProjectList.map((list, index) => (
        <AnimationOnScroll animateIn="fadeInLeft" key={index}>
          <Card>
            <CardLeft>
              <img src={list.img} alt={list.name} />
            </CardLeft>
            <CardRight>
              <h4>{list.title}</h4>
              <p>{list.description}</p>
              <TechCardContainer>
                {list.tech_stack.map((tech, index) => (
                  <TechCard key={index}>{tech}</TechCard>
                ))}
              </TechCardContainer>
              <BtnGroup>
                {list.github_url.length > 0 &&
                  <a
                    className="btn SecondaryBtn btn-shadow"
                    href={list.github_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Github
                  </a>
                }
                {list.demo_url.length > 0 &&
                  <a
                    className="btn PrimaryBtn btn-shadow"
                    href={list.demo_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Demo ➜
                  </a>
                }
              </BtnGroup>
            </CardRight>
          </Card>
        </AnimationOnScroll>
      ))}

      <AnimationOnScroll animateIn="fadeInLeft">
        <a
         
        href="https://github.com/alirazahub?tab=repositories" target="_blank" rel="noopener noreferrer">
          <h4 className="text-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">More Projects on Github</h4>
        </a>
      </AnimationOnScroll>
    </>
  );
}

export default ProjectCard;
