import React from "react";
import { stackList } from "../../data/ProjectData";
import {
  Image,
  Technologies,
  Tech,
  TechImg,
  TechName,
  ContactWrapper,
} from "./AboutElements";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import imagee from "../../assets/bg.png";
function About() {
  return (
    <>
      <svg
        height="100%"
        width="100%"
        id="svg"
        viewBox="0 0 1440 400"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 0,400 C 0,400 0,200 0,200 C 114.35714285714289,156.53571428571428 228.71428571428578,113.07142857142858 351,131 C 473.2857142857142,148.92857142857142 603.4999999999998,228.25 713,248 C 822.5000000000002,267.75 911.2857142857144,227.92857142857142 1029,210 C 1146.7142857142856,192.07142857142858 1293.3571428571427,196.03571428571428 1440,200 C 1440,200 1440,400 1440,400 Z"
          stroke="none"
          strokeWidth="0"
          fill="#151418ff"
          transform="rotate(-180 720 200)"
        ></path>
      </svg>
      <ContactWrapper id="about">
        <div className="Container">
          <div className="mb-10">
          <h1 className="text-4xl font-bold inline border-b-4 border-[#00FFCA]">
            About
          </h1>
          </div>
          <div className="BigCard">
            <AnimationOnScroll animateIn="fadeInLeft">
              <Image
                src={imagee}
                alt="man-svgrepo"
              />
            </AnimationOnScroll>
            <div className="AboutBio">
              <AnimationOnScroll animateIn="fadeInLeft">
              Hello! My name is <strong>Ali Raza. </strong>
                I am a <strong>Full Stack Devloper</strong> with a passion for creating innovative solutions to real-world problems. I am currently in my final year of study at COMSATS University Islamabad, where I am pursuing a Bachelor's degree in Computer Science. I have a strong interest in software development and have been working on a variety of projects in my spare time.
                also have a keen interest in the field of Artificial Intelligence and Machine Learning.
              </AnimationOnScroll>

              <br /><br />

              <AnimationOnScroll animateIn="fadeInLeft">
                I have experience in the following areas:
                <ul className="list-disc list-inside">
                  <li>Web Development</li>
                  <li>Mobile Development</li>
                  <li>Desktop Development</li>
                  <li>Machine Learning</li>
                  <li>Artificial Intelligence</li>
                </ul>
              </AnimationOnScroll>

              <br /><br />

              <AnimationOnScroll animateIn="fadeInLeft">
                Working collaboratively within a team towards a shared objective has been an incredibly rewarding and unique experience for me. I am eager to continue exploring exciting projects in the future, with a particular interest in web development and cross-platform mobile development.
                <div className="tagline2">
                  I have become confident using the following technologies:
                </div>
              </AnimationOnScroll>


              <Technologies>
                {stackList.map((stack, index) => (
                  <AnimationOnScroll animateIn="fadeInLeft" key={index}>
                    <Tech key={index} className="tech">
                      <TechImg src={stack.img} alt={stack.name} />
                      <TechName>{stack.name}</TechName>
                    </Tech>
                  </AnimationOnScroll>
                ))}
              </Technologies>
            </div>

          </div>
        </div>
      </ContactWrapper>
    </>
  );
}

export default About;
