import React, { useState } from "react";
import { ContactWrapper, Email } from "./ContactElements";
import { MdContentCopy } from "react-icons/md";
import { IconButton, Tooltip } from "@mui/material";
import Zoom from '@mui/material/Zoom';
import emailjs from './emailjsConfig';

import { AnimationOnScroll } from 'react-animation-on-scroll';
function Contact() {
  const [showTooltip, setShowTooltip] = useState(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText("alirazahub2@gmail.com");
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 700);
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const templateParams = {
      user_name: formData.name,
      user_email: formData.email,
      reply_to: formData.email,
      message: formData.message
    };

    emailjs.send(
      'service_t9u6ane',
      'template_467gyk9',
      templateParams
    )
    .then((response) => {
      alert('Email sent successfully!');
      setFormData({
        name: '',
        email: '',
        message: ''
      });
    })
    .catch((error) => {
      alert('Oops... ' + JSON.stringify(error));
    });
  };
  return (
    <ContactWrapper id="contact">

      <div className="Container">
      <div className="mb-10">
          <h1 className="text-4xl font-bold inline border-b-4 border-[#00FFCA]">
            Get in Touch
          </h1>
          </div>
        <AnimationOnScroll animateIn="fadeIn" >
          <div className="BigCard">
            <Email>
              <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px', rowGap: '10px', flexWrap: 'wrap', justifyContent: 'center' }} >
                <span>alirazahub2@gmail.com</span>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  open={showTooltip}
                  onClose={() => setShowTooltip(false)}
                  title="Copied!"
                  TransitionComponent={Zoom}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  placement="bottom"
                >
                  <IconButton onClick={copyToClipboard} >
                    <MdContentCopy size={25} style={{ cursor: 'pointer', color: "#151418" }} />
                  </IconButton>
                </Tooltip>
              </div>
            </Email>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="name" className="block font-medium">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block font-medium">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block font-medium">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                  rows="4"
                  required
                ></textarea>
              </div>
              <button
              type="submit"
                className=" bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
              >
                Send Message
              </button>
            </form>
          </div>
        </AnimationOnScroll>
      </div>
    </ContactWrapper>
  );
}

export default Contact;
