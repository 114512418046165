export const works = [
  {
    title: "Furniture e-commerce App",
    imgUrl:
      "https://cdn.dribbble.com/users/6870886/screenshots/20051488/media/f16e46c91563fd48d6b80c37b48ac6df.png?compress=1&resize=1000x750&vertical=top",
    tech: ["Flutter", "Figma", "Material UI"],
    workUrl:
      "https://www.behance.net/gallery/158475619/Furniture-Shopping-App-Design",
    duration: "400",
  },

  {
    title: "Photography Portfolio Website",
    imgUrl:
      "https://cdn.dribbble.com/users/6870886/screenshots/19725277/media/51d626308c85e403aa3e3ab67ba22022.png?compress=1&resize=400x300&vertical=top",
    tech: ["HTML", "CSS", "Bootstrap 5"],
    workUrl:
      "https://www.behance.net/gallery/155476041/UI-Design-Photography-Portfolio-Website",
    duration: "500",
  },
  {
    title: "Portfolio Maker",
    imgUrl:
      "https://cdn.dribbble.com/users/6870886/screenshots/20208089/portfilio1_cover.jpg",
    tech: ["Django", "CSS", "Boottsrap 5", "Jquery"],
    workUrl: "https://github.com/LaithMahdi/portfolio_maker",
    duration: "600",
  },
  {
    title: "Maklti food app",
    imgUrl:
      "https://cdn.dribbble.com/users/6870886/screenshots/19057557/cover_dribbble.png",
    tech: ["Flutter", "Figma", "Material UI"],
    workUrl:
      "https://www.behance.net/gallery/150189805/MAKLTI-Food-Delivery-App",
    duration: "700",
  },
];

// Array of experience objects
export const experiences = [
  {
    date: "June 2023 - Present",
    company: "JSOFT",
    position: "Full Stack Developer",
    description:
      "I am working as Full Stack Developer. I am working in MERN technologies plus Python's FastApi to build the backend with integration of OpenAi and OLlama. Also help company to develop the AI chat bots.",
  },
  {
    date: "July 2023 - Nov 2023",
    company: "ZySoftec",
    position: "MERN Stack Developer (Remote)",
    description:
      "I worked as a MERN Stack Intern at ZySoftec. I was responsible for developing and maintaining the web applications of the company.",
  },
  {
    date: "March 2021 - Present",
    company: "Self employed",
    position: "Freelancer",
    description:
    "I specialize in developing mobile applications and websites for small businesses and large corporations. I have successfully completed more then 20 projects, including mobile applications and  websites. I have also worked on many projects as a team member.",
  },
  {
    date: "October 2020 - February 2021",
    company: "KICS, UET Lahore",
    position: "Network Administrator Intern",
    description:
      "I worked as a Network Administrator Intern at KICS, UET Lahore. I was responsible for managing the network infrastructure of the institute. I also worked on a project to automate the process of assigning IP addresses to the devices connected to the network.",
  },
];