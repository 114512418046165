// --------------------------------------- Project List
export const ProjectList = [
  {
    img: "/chainlit.png",
    title: "AI Chat support for Medical",
    description:
      "Online Web Platform for Doctors to ask questions",
    tech_stack: ["Python", "Machine Learning", "HuggingFace","AI"],
    github_url: "",
    demo_url: "https://drive.google.com/drive/folders/1h7pPJGECS5ORRFFBpO1BY14La5dBSthS?usp=sharing",
  },
  {
    img: "/fast.jpeg",
    title: "ASD AI/ML Web App",
    description:
      "Online Web Platform for Doctors to make predictions wheather child is Autistic or Not",
    tech_stack: ["ReactJs", "Python", "FastAPI", "MongoDB", "Machine Learning", "Tailwind CSS","Ant Design"],
    github_url: "",
    demo_url: "https://drive.google.com/file/d/1rOKteZtJWqI7g1G82TK41c7N5R4TQ9Sk/view?usp=sharing",
  },
  {
    img: "/art.PNG",
    title: "Art Gallery",
    description:
      "An app where users can upload their artworks and sell them online.",
    tech_stack: ["ReactJs", "ExpressJs", "NodeJs", "MySQL", "ReactQuery", "Tailwind CSS","Ant Design"],
    github_url: "",
    demo_url: "https://art-gallery-zysoftec.vercel.app/",
  },
  {
    img: "/dating.PNG",
    title: "Matrimonial And Dating App",
    description:
      "A matrimonial and dating app where users can find their life partners and chat with them online.",
    tech_stack: ["ReactJs", "ExpressJs", "NodeJs", "MongoDB", "Redux", "Bootstrap","Ant Design"],
    github_url: "",
    demo_url: "https://lovodi.netlify.app/",
  },
  {
    img: "/chat.PNG",
    title: "WatchGPT",
    description:
      "WatchGPT(Movie Recommendation) is react native app where users can recommendations of movies and tv shows based on their preferences.",
    tech_stack: ["OpenAi","React Native", "Material UI"],
    github_url: "https://github.com/alirazahub/watchGPT",
    demo_url: "",
  },
  {
    img: "/weather.PNG",
    title: "The Weather App",
    description:
      "A weather app where users can get the weather of any city in the world. The Weather is a React.js application with Tailwind CSS integration that allows users to retrieve weather information for various cities using the OpenWeatherMap API and the RapidAPI for city suggestions. This application provides real-time weather updates and offers a user-friendly interface for a seamless experience.",
    tech_stack: ["ReactJs","Python", "Django", "Bootstrap"],
    github_url: "https://github.com/alirazahub/the-weather",
    demo_url: "https://alirazahub.github.io/the-weather/",
  },
];

// --------------------------------------- Skills

export const stackList = [
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
    name: "HTML",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg",
    name: "CSS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/bootstrap/bootstrap-original.svg",
    name: "Bootstrap",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/materialui/materialui-original.svg",
    name: "Material UI",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/tailwindcss/tailwindcss-original-wordmark.svg",
    name: "Tailwind CSS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
    name: "ReactJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nextjs/nextjs-original.svg",
    name: "Nextjs",
  }, {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/redux/redux-original.svg",
    name: "Redux",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
    name: "JavaScript",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
    name: "Typescript",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/jquery/jquery-original.svg",
    name: "Jquery",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
    name: "React Native",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
    name: "NodeJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
    name: "Python",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/flask/flask-original.svg",
    name: "Flask",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/django/django-plain-wordmark.svg",
    name: "Django",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/c/c-original.svg",
    name: "C",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/firebase/firebase-plain.svg",
    name: "Firebase",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg",
    name: "MongoDB",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original.svg",
    name: "MySQL",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/sqlite/sqlite-original.svg",
    name: "SQLite",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
    name: "Java",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/figma/figma-original.svg",
    name: "Figma",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/vscode/vscode-original.svg",
    name: "VS Code",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg",
    name: "Git",
  }, {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/github/github-original.svg",
    name: "Github",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/heroku/heroku-original.svg",
    name: "Heroku",
  },
];
