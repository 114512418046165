import React, { useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import Header from "../Header/Header";
import {
  HeroContainer,
  HeroWrapper,
  HeroLeft,
  HeroRight,
  Image,
  ScrollDown,
  ScrollLink,
} from "./HeroElements";
import { TypeAnimation } from 'react-type-animation';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import imagee from "../../assets/bg.png"

function Hero() {
  const [isOpen, setIsOpen] = useState(false);
  const [showSubtitle, setShowSubtitle] = useState(false);
  const [showScrollDown, setShowScrollDown] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <main>
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <Header toggle={toggle} />
      <HeroContainer>
        <HeroWrapper>
          <HeroLeft>
            <AnimationOnScroll animateIn="fadeIn" >
              <TypeAnimation
                cursor={false}
                sequence={[
                  'Hi, I\'m Ali Raza.',
                  () => setShowSubtitle(true)
                ]}
                speed={{ type: "keyStrokeDelayInMs", value: 150 }}
                wrapper="h1"
                repeat={0}
              />
              {showSubtitle &&
                <TypeAnimation
                  cursor={true}
                  sequence={[
                    500,
                    'A Full-Stack Developer.',
                    500,
                    'I design and code beautifully simple things, and I love what I do.',
                    500,
                    'A problem solver.',
                    500,
                    'An innovative thinker.',
                    500,
                    'A....',
                    500,
                    'A.... cool guy?',
                    500,
                    "Ok...",
                    500,
                    "Ok...  I'm running out of ideas...",
                    500,
                    "Uhh...",
                    500,
                    "Uhh... you can scroll down to see my projects now...",
                    300,
                    () => setShowScrollDown(true),
                    500,
                    "Seriously, my projects are really cool, go check them out!",
                    500,
                    "You're uh...",
                    500,
                    "You're uh... still here?",
                    500,
                    "Ok, this has been fun, but I'm gonna restart the loop now...",
                    500,
                    "Or...",
                    500,
                    "Or... I could scroll you by force! Muahaha!",
                    500,
                    "See ya! :)",
                    500,
                  ]}
                  speed={50}
                  deletionSpeed={65}
                  wrapper="h5"
                  repeat={Infinity}
                />
              }
            </AnimationOnScroll>

          </HeroLeft>
          <HeroRight>
            <AnimationOnScroll animateIn="fadeIn">
              <Image
                src={imagee}
                alt="man-svgrepo"
              />
            </AnimationOnScroll>
          </HeroRight>
        </HeroWrapper>
        {showScrollDown &&<AnimationOnScroll animateIn="flipInX" offset={0}>
        <ScrollDown to="projects" id="scrollDown">
          <ScrollLink>
            Scroll down
            <img
              src="/scroll-down.svg"
              alt="scroll-down"
            />
          </ScrollLink>
        </ScrollDown>
        </AnimationOnScroll>}
      </HeroContainer>
    </main>
  );
}

export default Hero;
